import React from "react";

const Home = () => {
  return (
    <div className="home-page">
      To be able to access the page: Setting - Login
    </div>
  );
};

export default Home;
